<template>
<div class="competi-content">
    <div class="col-12 mb-3">
      <social-filter :post="post"></social-filter>
    </div>
    <div class="col-12 component-competidor">
        <card type="filter default">
            <div class="row">
                <div class="col-12 col-lg-6">
                  <h3>{{$t('competidore.title')}}</h3>
                </div>
                <div class="col-12 col-lg-6 text-right">
                  <button type="button" @click="wizzAdd" data-toggle="modal" data-target="#exampleModal1" class="btn btn-default" aria-label="Left Align">
                    <font-awesome-icon icon="plus-square" size="1x"/>
                    {{$t('competidore.btn1')}}
                  </button>
                  <download-excel :data="fila" :name="$t('competidore.title')" class="downloadexcel">
                    <button type="button" class="btn btn-default" aria-label="Left Align">
                      <font-awesome-icon icon="download" size="1x"/>
                      {{$t('competidore.btn2')}}
                    </button>
                  </download-excel>

                </div>
              <div class="col-12">
                  <TableView
                    :headers="columns"
                    :rows="fila"      
                    :sort="sort2"
                    :pagination="pagination"
                    css-style="custom-style"
                    ref="tblCompetitors"      
                  >
                    <template v-slot:items="{ row }">
                        <td scope="row">
                        <div class="form-group" >
                            <label :for="row.ID" class="label-cbx">
                                <input name="empresa" :id="row.ID" type="radio" class="d-none" @click="viewData(row.ID)" >
                                <div class="checkbox">
                                <svg width="20px" height="20px" viewBox="0 0 20 20"><path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path><polyline points="4 11 8 15 16 6"></polyline></svg>
                                </div> 
                            </label>
                            </div>
                        </td>
                        
                        <td>{{row.Organizacion}}</td>
                        <td>{{row.Likes}}</td>
                        <td>{{row.Post}}</td>
                        <td>{{row.Reacciones}}</td>
                        <td>{{row.Compartidos}}</td>
                        <!-- <td>{{row.Reiting}}</td> -->
                        <td>
                            <button type="button" class="btn" :id="'delete'+row.ID" @click="deleteCompet(row.ID)" ><font-awesome-icon icon="trash-alt" size="1x"/></button>
                        </td>      
                    </template>
                    
                    <template v-slot:no-data>
                      <span>No data</span>
                    </template>
                  </TableView>
              </div>
            </div>
        </card>
        <card type="filter default mt-3">
          <template slot="header">
            <h2 class="card-category">{{$t('competidore.titlecard1')}}</h2>
            <!--<h3 class="card-title">260</h3>-->
          </template>
          <p v-if="redesSociales == null">{{$t('competidore.nodata')}}</p>
          <line-chart
            id="compe-id"
            class="chart-area" style="height: 300px"
            :chartData="redesSociales"
            :extraOptions="extraOptionsLine" v-if="loaded"
          ></line-chart>
        </card>
        <div class="row">
          <div class="col-12 col-md-6 mt-4" v-if="loaded">
            <card type="chart" class="card-heig">
              <template slot="header">
                <h2 class="card-category">{{$t('competidore.titlefuentes')}}</h2>
                <p>{{chartdata[0].nombre}}</p>
                <h3 class="card-title">
                  <i class="tim-icons icon-bell-55 text-primary"></i> {{  }}
                </h3>
              </template>
              <div class="legend-con" v-html="message"></div>
              <doughnut
                class="chart-area" style="height: 200px;"
                :chartData="chartdata"
                :options="extraOptionsFuente"
                @generated="setLegend"
              ></doughnut>
            </card>
          </div>
          <div class="col-12 col-md-6 mt-4" v-if="loaded">
            <card type="chart" class="card-heig">
              <template slot="header">
                <h2 class="card-category">{{$t('competidore.titlefuentes')}}</h2>
                <p v-if="chartdata2.length > 0">{{chartdata2[0].nombre}}</p>
                <h3 class="card-title">
                  <i class="tim-icons icon-bell-55 text-primary"></i> {{  }}
                </h3>
              </template>
              <div class="legend-con" v-html="message2"></div>
              <doughnut
                class="chart-area" style="height: 200px;"
                :chartData="chartdata2"
                :options="extraOptionsFuente"
                @generated="setLegend2"
              ></doughnut>
            </card>
          </div>
          <div class="col-12 col-md-6 mt-4" v-if="loaded">
            <card type="chart" class="card-heig">
              <template slot="header">
                <h2 class="card-category">{{$t('competidore.titleReactInsTwit')}}</h2>
                <p v-if="chartdata3.length > 0">{{chartdata2[0].nombre}}</p>
                <h3 class="card-title">
                  <i class="tim-icons icon-bell-55 text-primary"></i> {{  }}
                </h3>
              </template>
              <div class="legend-con" v-html="message3"></div>
              <doughnut
                class="chart-area" style="height: 200px;"
                :chartData="chartdata3"
                :options="extraOptionsFuente"
                @generated="setLegend3"
              ></doughnut>
            </card>
          </div>
          <div class="col-12 col-md-6 mt-4" v-if="loaded">
            <card type="chart" class="card-heig">
              <template slot="header">
                <h2 class="card-category">{{$t('competidore.titleReactFace')}}</h2>
                <p v-if="chartdata4.length > 0">{{chartdata2[0].nombre}}</p>
                <h3 class="card-title">
                  <i class="tim-icons icon-bell-55 text-primary"></i> {{  }}
                </h3>
              </template>
              <div class="legend-con" v-html="message4"></div>
              <doughnut
                class="chart-area" style="height: 200px;"
                :chartData="chartdata4"
                :options="extraOptionsFuente"
                @generated="setLegend4"
              ></doughnut>
            </card>
          </div>
          <div class="col-12">
            <card type="filter default mt-3">
              <template slot="header">
                <h2 class="card-category">{{$t('competidore.titlecard2')}}</h2>
                <p v-if="cardRanking == null">{{$t('competidore.nodata')}}</p>
              </template>
            </card>
          </div>
          <div class="col-12 target-co"  v-for="item in computedCardRanking" :key="item.id">
            <card type="filter default mt-3" v-if="cardRanking != null">
              <div class="row justify-center">
                <div class="col-12 col-lg-2">
                  <div class="img-use" v-if="item.ImageProfile" :style="'background: url('+item.ImageProfile+') center center / cover'"></div>
                  <div class="img-use" v-else  :style="{'background': 'url(' + require('@/assets/logo.png') + ') center center / contain'}"></div>
                </div>
                <div class="col-12 col-lg-7">
                  <img v-if="item.Red_Social == 'Facebook'" src="../../assets/image/facebook.png" alt="Facebook" style="height: 70px " />
                  <img v-else-if="item.Red_Social == 'Instagram'" src="../../assets/image/instagram.png" alt="Facebook" style="height: 80px " />
                  <img v-else src="../../assets/image/twiter.png" alt="Twitter" style="height: 60px " />
                  <h3>{{item.NameUser}} </h3>
                  <p>{{item.Nombre}}</p>
                </div>
                <div class="col-12 col-lg-3">
                  <p><span class="fas fa-calendar-check"></span> {{item.Fecha}} </p>
                </div>
                <div class="col-12 col-lg-1"></div>
                <div class="col-12 col-lg-10">
                  <p>{{item.TextPost}}</p>
                </div>
              </div>
              <template slot="footer">
                <div class="row justify-content-end">
                  <div class="col-10">
                    <a :href="item.Link" title="Ir a el ver perfil"><i class="fas fa-share" aria-hidden="true"></i> Ver el perfil</a>
                    <!-- <a :href="item.Link" title="Ir a el ver perfil"><i class="fab fa-android" aria-hidden="true"></i>  Ver el perfil</a>
                    <a :href="item.Link" title="Ir a el ver perfil"><i class="fab fa-android" aria-hidden="true"></i>  Ver el perfil</a> -->
                  </div>
                </div>
              </template>
            </card>
          </div>
        </div>
        <div class="modal fade" ref="exampleModal1" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModal1" aria-hidden="true">
          <div class="modal-dialog  modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-body">
                <h3>{{$t('competidore.wizard.addCompetidor')}}</h3>
                <p>{{$t('competidore.wizard.insertCompe')}}</p>
                <form-wizard
                  color="#0C75EC" ref="prueb"
                  :nextButtonText="$t('accounts.btn1')"
                  :backButtonText="$t('accounts.btn2')"
                  :finishButtonText="$t('accounts.btn3')"
                  @on-complete="onComplete"
                  
              >
                  <!-- <tab-content
                  :title="$t('accounts.wizard1.step1')"
                  icon="fas fa-id-card"
                  :before-change="() => validateStep('step1')"
                  >
                  <div class="row" ref="step1" @on-validate="mergePartialModels">
                      1
                  </div>
                  </tab-content> -->
                  <tab-content
                      :title="$t('competidore.wizard.nameCompe')"
                      icon="fab fa-pencil"
                      :before-change="() => validateStep('step2')"
                  >
                    <div class="col-12" ref="step2" @on-validate="mergePartialModels">
                      <text-input type="text" name="competitor" :placeholder="$t('competidore.wizard.placeholder')" v-model="competitor_name" ></text-input>
                    </div>
                  </tab-content>
                  <tab-content
                  title="Facebook"
                  icon="fab fa-facebook"
                  :before-change="() => validateStep('step2')"
                  >
                  <div class="col-12" ref="step2" @on-validate="mergePartialModels">
                    
                    <!-- <v-select taggable placeholder="enter an email" onTab v-model="facebook1" /> -->
                    <v-select
                        label="name"
                        :options="fb_data"
                        v-model="facebook"
                        @search="selectFbUsers"
                        @search:blur="selectFbUsersFocus"
                        ref="selectFb"
                        style="display: inline-block; width: 70%;"
                    >
                      <template slot="no-options">
                        {{$t('competidore.wizard.itemSearch')}}
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <img :src='option.profile_image_url'/>
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <img :src='option.profile_image_url'/>
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>
                    <button style="margin-left:15px" class="btn-wizard">{{$t('competidore.wizard.btnSearch')}}</button>
                  </div>
                  </tab-content>
                  <tab-content title="Instagram" icon="fab fa-instagram">
                  <div class="col-12" ref="step3" @on-validate="mergePartialModels">
                      <text-input v-if="disabledInstagram" type="text"  name="instagram" :placeholder="$t('competidore.wizard.placeholder')" v-model="instagram" ></text-input>
                      <div class="compe-ins" v-else>
                        <p>{{$t('competidore.wizard.restInst')}}</p>
                        <text-input  type="text" disabled="disabled" name="instagram" placeholder="Ingresa el nombre de usuario" v-model="instagram" ></text-input>
                      </div>
                  </div>
                  </tab-content>
                  <tab-content title="Twitter" icon="fab fa-twitter">
                  <div class="col-12" ref="step4" @on-validate="mergePartialModels">
                    <v-select
                        label="name"
                        :options="twitter_data"
                        @search="selectTwitterUsers"
                        v-model="twitter"
                        @search:blur="selectTwitterUsersFocus"
                        style="display: inline-block; width: 70%;"
                        ref="selectTwi"
                    >
                      <template slot="no-options">
                        {{$t('competidore.wizard.itemSearch')}}
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <img :src='option.profile_image_url'/>
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <img :src='option.profile_image_url'/>
                          {{ option.name }}
                        </div>
                      </template>
                    </v-select>
                    <button style="margin-left: 15px" class="btn-wizard">{{$t('competidore.wizard.btnSearch')}}</button>
                  </div>
                  </tab-content>
                  <template slot="footer" slot-scope="props">
                  <div class="wizard-footer-left">
                      <wizard-button  v-if="props.activeTabIndex > 0 " @click.native="props.prevTab()" :style="props.fillButtonStyle">{{$t('competidore.btnwizard1')}}</wizard-button>
                    </div>
                    <div class="wizard-footer-right">
                      <button type="button" ref="closemodal" data-dismiss="modal">{{$t('competidore.btnwizard2')}}</button>
                      <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle">{{$t('competidore.btnwizard3')}}</wizard-button>
                      <wizard-button v-else @click.native="onComplete('Done')" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">  {{props.isLastStep ? 'Done' : 'Next'}}</wizard-button>
                    </div>
                  </template>
              </form-wizard>
              </div>
            </div>
          </div>
        </div>
        <modalpeq group="competidores1" position="bottom left"  />
    </div>
</div>
</template>

<script>
// import { rows } from './data'
import moment from "moment";
import LineChart from "@/components/charts/LineCharts";
import Doughnut from "@/components/charts/Doughnut";
import * as chartConfigs from "@/components/charts/config";
import config from "@/components/charts/configlobal";
import TableView from '@/components/table/TableView.component'
import Card from "@/components/card/Card.component";
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import TextInput from "@/components/inputForms/InputForms.component";
import {mapState, mapMutations} from "vuex";
import { createHelpers } from 'vuex-map-fields';
import SocialFilter from "@/components/redesSociales/social.component";
const { mapFields } = createHelpers({
  getterType: 'menciones/getField',
  mutationType: 'menciones/updateField',
});
export default {
  name: "Competidores",
  components: {
    Card,
    TableView,
    FormWizard,
    TabContent,
    TextInput,
    WizardButton,
    LineChart,
    Doughnut,
    SocialFilter
  },
  data() {
    return {
        searchQuery: '',
        items: [],
        
        sort1:{
          field: 'first_name',
          order: 'asc'
        },
        sort2:{
          field: 'email',
          order: 'asc'
        },      
        pagination:{
          itemsPerPage: 3,
          align: 'center',
          visualStyle: 'select'
        },
        columns: null,
        // variables de el wizard
        facebook: '',
        facebook1: '',
        instagram: '',
        twitter: '',
        twitter1: '',
        competitor_name:'',
        twitter_data: [],
        fb_data: [],
        awaiting_fb_data: false,
        fb_search_field_disabled: false,
        json_fields: {
          "Complete name": "name",
          City: "city",
          country: "country",
          birthdate: "birthdate"
        },
        json_data: [
          {
            name: "Tony Peña",
            city: "New York",
            country: "United States",
            birthdate: "1978-03-15"
          },
          {
            name: "Thessaloniki",
            city: "Athens",
            country: "Greece",
            birthdate: "1987-11-23"
          },
        ],
        //variables data
        fila: [],
        extraOptionsLine: chartConfigs.greenChartOptions,
        postRedes: null,
        loaded: false,
        redesSociales: null,
        idCompetidor: null,
        chartdata: null,
        chartdata2: null,
        chartdata3: null,
        chartdata4: null,
        extraOptionsFuente: chartConfigs.doughnutChartOptionsFuentes,
        message: 'Hello World',
        message2: 'Hello World',
        message3: 'Hello World',
        message4: 'Hello World',
        cardRanking: null,
        post: null,
        disabledInstagram: true
    }
  },
  created() {
    let columns1 = [
      {"label":"ID","field":"ID","sortable":false,"type":"Number"},
      {"label": this.$tc('competidore.item1'),"field":"Organizacion","sortable":true,"type":"String"},
      {"label":"Likes","field":"Likes","sortable":true,"type":"String"},
      {"label":"Post","field":"Post","sortable":true,"type":"String"},
      {"label": this.$tc('competidore.item4'),"field":"Reacciones","sortable":true,"type":"Number"},
      {"label": this.$tc('competidore.item5'),"field":"Compartidos","sortable":true,"type":"String"},
      // {"label":"Rating","field":"Reiting","sortable":true,"type":"String"},
      {"label":"DE","sortable":false,"type":"Number"},
    ]
    this.columns = columns1;
    this.getCompetitors()
    this.dateNow();
    const ins = this.social[0].socialweb.filter(elem => elem.Red_Social == "Instagram")
    if(ins.length < 1){
      this.disabledInstagram = false
    }
    // this.Carga()
    // this.pruebas()
  },
  computed: {
    ...mapFields(['tags']),
    ...mapState(['social', "socialFacebook", "socialInstagram", "socialTwitter"]),
    ...mapState("vision", ["dateRange", "fechas"]),
    computedCardRanking(){
      if(this.cardRanking){
        
        this.cardRanking.forEach(x => {
          if(x.Red_Social == 'Twitter'  ){
            let contador = x.Tw_Retweet_Count
            x.totalreaccion = contador;
          }
          else if(x.Red_Social == 'Instagram'  ){
            let contador = x.Intg_Media_Like_count
            x.totalreaccion = contador
          }
          else if(x.Red_Social == 'Facebook'  ){
            let contador = x.Fbk_ReaANGRY + x.Fbk_ReaHAHA + x.Fbk_ReaLOVE + x.Fbk_ReaLikes + x.Fbk_ReaWOW + x.Fbk_Reacciones
            x.totalreaccion = contador
          }
          let date = moment(x.Fecha, "MMMM DD YYYY").format("YYYY/MM/DD HH:MM");
          if(date == "Invalid date"){
             x.Fecha = moment(new Date(x.Fecha), "MMMM DD YYYY").format("YYYY/MM/DD HH:MM");
          }
          else{
            x.Fecha = date
          }
        })
        this.cardRanking.sort(function(x, y) {
          return y.totalreaccion- x.totalreaccion;
        });
        this.cardRanking = this.cardRanking.slice(0, 10);
        return this.cardRanking;
      }
      else{
        return null
      }
    }
  },
  methods: {
    ...mapMutations(["Carga"]),
    ...mapMutations("vision", ["dateNow"]),
    // async pruebas(){
    //   const sync_data = await this.axios.post(`/bextsocial/syncaccounts/jobstatus`, {
    //     id_empresa: 275 ,
    //     id_sync: 132,
    //   });
    //   console.log(sync_data);
    // },
    wizzAdd(){
      this.$refs.prueb.reset()
    },
    mergePartialModels(model, isValid) {      
      if (isValid) {
        // merging each step model into the final model
        this.finalModel = Object.assign({}, this.finalModel, model);
      }
    },
    validateStep(step) {
      return true;
    },
    async onComplete(){
      this.$refs.closemodal.click()
      this.Carga();
      // Get Account Id
      const data =  this.social.filter(item => item.view == true);
      const account_data = data.shift();
      const id_empresa =  account_data.Id_Empresa;
      const nombre_empresa = account_data.empresa;
      // Create Competitor data
      const competitor_data = {
        competitor_name: this.competitor_name,
        id_empresa: id_empresa,
        user_instagram: this.instagram,
        user_twitter: (this.twitter) ? this.twitter.id : '',
        id_fb: (this.facebook) ? this.facebook.id : ''
      };
      var pruebaq = []
      try {
        // Create the competitor before it make the async process
        let create_competitor = await this.axios.post('/bextsocial/addcompetitors', competitor_data);
        if(create_competitor){
          if(create_competitor.status == 200) {
            if(create_competitor.data && create_competitor.data.status == 200) {
              // Call the async process
              // Data for SYNC table
              let sync_data = {
                descripcion: `Sincronización Competidor ${this.competitor_name}`,
                id_empresa: id_empresa,
                id_fb_sync: null,
                id_instagram_sync: null,
                id_twitter_sync: null,
              }
              let syncFB = null;
              let syncInstagram = null;
              let syncTwitter = null;
              if(this.facebook) {
                // Sync Facebook
                console.log("Call the sync for Facebook");
                syncFB = await this.axios.post(`/bextsocial/syncfb`, {
                  account_id: nombre_empresa,
                  id_competitor: create_competitor.data.id_competitor
                });
                debugger
                if('data' in syncFB) {
                  sync_data.id_fb_sync = syncFB.data.job_id;
                  pruebaq.push(syncFB.data.job_id)
                }
              }
              if(this.twitter) {
                console.log("Call the sync for Twitter");
                syncTwitter = await this.axios.post(`/bextsocial/synctwitter`, {
                  account_id: nombre_empresa,
                  id_competitor: create_competitor.data.id_competitor
                });
                // Add data for sync table
                if('data' in syncTwitter) {
                  sync_data.id_twitter_sync = syncTwitter.data.job_id;
                  pruebaq.push(syncTwitter.data.job_id)
                }
              }
              if(this.instagram) {
                console.log("Call the sync for Instagram");
                syncInstagram = await this.axios.post(`/bextsocial/syncinstagram`, {
                  account_id: nombre_empresa,
                  id_competitor: create_competitor.data.id_competitor
                });
                // Add data for sync table
                if('data' in syncInstagram) {
                  sync_data.id_instagram_sync = syncInstagram.data.job_id;
                  pruebaq.push(syncInstagram.data.job_id)
                }
              }
              console.log();
              // Create Sync data on the table
              const responseJob = await this.axios.post(`/bextsocial/syncaccounts/addsyncinfo`, sync_data);
              let finish_sync = false;
              debugger
              while(!finish_sync) {
                // Check the status of the sync
                // const pruebqwcf = await this.pruebfd(pruebaq, id_empresa)
                const sync_data1 = await this.axios.post(`/bextsocial/syncaccounts/jobstatus`, {
                  id_empresa: id_empresa,
                  id_sync: responseJob.data[0].Id,
                });
                // If not have any data, finish the cycle
                if(!sync_data1.data){
                  finish_sync = true;
                }
                finish_sync = this.checkStatus(sync_data1.data);
              }
              this.getCompetitors()
              this.Carga()
              

              // TODO: Finish the wizard
            }
            else {
              // TODO: The competitor already exists needs management
              this.Carga()
              console.log(create_competitor.data.mensaje);
            }
          }
        }
        
      } catch (error) {
        // TODO: Manage errors on execution
        this.Carga()
        console.log(error);
      }
    },
    checkStatus(jobs_data){
      // Checkby key to set the structure
      jobs_data = Object.values(jobs_data)
      const isBelowThreshold = (currentValue) => currentValue.progress == 100;
      var completed_elements = jobs_data.every(isBelowThreshold);
      // Return if the cycle must continue or not
      return completed_elements;
    },
    selectTwitterUsersFocus(){
      if(this.twitter1 != ""){
        this.selectTwitterUsers(this.twitter1, true)
      }
    },
    async selectTwitterUsers(val, vali){
      // Restrict at least have three chars
      try {
        if(vali == true) {
          let twitter_users = await this.axios.post(`/bextsocial/gettwitterusers`, {user: val});
          if(twitter_users.data){
            this.twitter_data = twitter_users.data;
          }
          else {
            this.twitter_data = [];
          }
          this.$refs.selectTwi.open = true
        }
        else{
          this.twitter1 = val
        }
      } catch (error) {
        console.log(error);
      }
      
    },
    selectFbUsersFocus(){
      debugger;
      if(this.facebook1 != ""){
        this.selectFbUsers(this.facebook1, true)
      }
    },
    async selectFbUsers (val, vali){
      debugger;
      // Restrict at least have three chars
      try {
        // this.facebook1 = "" ? loading(true) : this.facebook1
        if(vali == true){
          const data =  this.social.filter(item => item.view == true);
          const account_data = data.shift();
          const response = await  this.axios.post(`/bextsocial/getfbusers`, {
            user: val,
            account_id: account_data.empresa
          })
          if(response.data){
                  this.fb_data = response.data;
                }
                else {
                  this.fb_data = [];
                }
                this.awaiting_fb_data = false;
                this.fb_search_field_disabled = false;
                this.$refs.selectFb.open = true
          
            //   .then(result => {
            //     debugger;
                
            //   }, 350)
            //   .catch(error => {
            //     debugger;
            //     console.log(error);
            // });
        }
        else{
          this.facebook1 = val;
        }
      } catch (error) {
        console.log(error);
      }
      
    },
    async aw(data){
        var redSq = [];
        if(this.socialFacebook){
            redSq.push("Facebook");
        }
        if(this.socialInstagram){
            redSq.push("Instagram");
        }
        if(this.socialTwitter){
            redSq.push("Twitter");
        }
        var qeq = []
        for (const item of data) {
            let response = await this.axios.get(`/bextsocial/getinfocompetitors/${item.Id_Competidor}/${redSq}`)
            qeq = qeq.concat(response.data)
        }
        return qeq
    },
    async getCompetitors() {
        this.Carga();
        const id = this.social.filter(item => item.view == true)
        const { data } = await this.axios.get(`/bextsocial/getcompetitors/${id[0].Id_Empresa}`)
        if(data){
            const compe = await this.aw(data)
            if(compe.length != 0){
                let arrCompetidores = [];
                console.log(compe);
                console.log(data);
                data.forEach(item => {
                    var countlikes = 0;
                    var countreactions = 0;
                    var reactionsFacebook = 0
                    const post = compe.filter(element => element.Id_Competidor == item.Id_Competidor)
                    post.filter(element => element.Intg_Media_Like_count != undefined ? countlikes = countlikes + parseInt(element.Intg_Media_Like_count) : countlikes )
                    post.filter(element => element.twit_likes != undefined ? countlikes = countlikes + parseInt(element.twit_likes) : countlikes )
                    post.filter(element => element.Tw_Retweet_Count != undefined ? countreactions = countreactions + parseInt(element.Tw_Retweet_Count) : countreactions)
                    post.filter(element => element.Fbk_Reacciones != undefined ? reactionsFacebook = reactionsFacebook + parseInt(element.Fbk_Reacciones)  : reactionsFacebook)
                    arrCompetidores.push({
                        "ID": item.Id_Competidor,
                        "Organizacion": item.Competidor,
                        "Likes": countlikes,
                        "Post": post.length,
                        "Reacciones": reactionsFacebook,
                        "Compartidos": countreactions,
                        // "Reiting": 0,
                        "DE": item.Id_Competidor,
                    })
                })
                this.fila = arrCompetidores
                this.Carga();
            }
            else{
                this.$hijonew({
                    group: 'competidores1',
                    type: 'warning',
                    title: "Advertencia ",
                    text: 'Suerte',
                    duration: 1000,
                    speed: 1000
                })
            }
        }
        else{
            this.Carga();
            this.$hijonew({
                group: 'competidores1',
                type: 'warning',
                title: "Advertencia ",
                text: this.$t('competidore.error'),
                duration: 1000,
                speed: 1000
            })
        }
    },
    //GET TAGS
    getTag(){
      let prueba = "";
        // let objetotags = JSON.parse(localStorage.getItem("rest"));
        let objetotags = this.tags;
        if(objetotags != null){
          objetotags.forEach(function(x, i) {
            if (i % 2 === 0) {
              if (i == 0) {
                if (i == objetotags.length - 1) {
                  prueba = x.text;
                } else {
                  prueba = x.text + ",";
                }
              } else if (i == objetotags.length - 1) {
                prueba = prueba + "," + x.text;
              } else {
                prueba = prueba + "," + x.text + ",";
              }
            } else {
              prueba = prueba + x.text;
            }
          });
          return prueba;
        }
        else{
          alert("error: no ha registro ninguna busqueda");
        }
    },
    setLegend (html) {
      this.message = html
    },
    setLegend2 (html) {
      this.message2 = html
    },
    setLegend3 (html) {
      this.message3 = html
    },
    setLegend4 (html) {
      this.message4 = html
    },
    async viewData(id){
      try {
        this.idCompetidor = id;
        this.Carga()
        // Verificar si hay tag en el buscador
        const tag = this.getTag();
        if(tag != ""){
          var redSq = [];
          if(this.socialFacebook){
            redSq.push("Facebook");
          }
          if(this.socialInstagram){
            redSq.push("Instagram");
          }
          if(this.socialTwitter){
            redSq.push("Twitter");
          }
          var prueba = this.fechas[0].start;
          var prueba1 = this.fechas[0].end;
          // get id empresa
          const ids = this.social.filter(item => item.view == true)
          // get data of competitors
          let publishing = await this.axios.get(`/bextsocial/publicationcompetidores/${ids[0].Id_Empresa}/${id}/${tag}/${prueba},${prueba1}/${redSq}`)
          if(publishing.data == 'No se encontro data relacionada a esas fechas y tags'){
            this.Carga();
            this.post = null
            this.$hijonew({
              group: 'competidores1',
              type: 'warning',
              title: this.$t('dashboard.titlemodalad'),
              text: this.$t('competidore.modalerr'),
              duration: 1000,
              speed: 1000
            })
          }
          else{  
            // FIXME
            //Get redes sociales
            this.post = publishing.data
            console.log(publishing.data);
            this.cardRanking = publishing.data
            var reso = []
            // get the social networks that exist
            publishing.data.forEach((element,index) =>{
              const redsocial = reso.filter(x => x.RedSocial == element.Red_Social);
              if(redsocial.length == 0){
                  reso.push({
                    "RedSocial": element.Red_Social,
                });
              }
            })
            
            // Get my account and competitor networks
            var reso1 = []
            publishing.data.forEach((element,index) =>{
              const redsocial = reso1.filter(x => x.RedSocial == element.Red_Social && x.Nombre == element.Nombre);
              if(redsocial.length == 0){
                  reso1.push({
                    "RedSocial": element.Red_Social,
                    "Nombre": element.Nombre
                });
              }
            })

            // console.log(reso, reso1);

            //Get cuenta y competidor
            const competitors = publishing.data.filter(element => element.Id_Competidor != null && element.Id_Competidor != 0)
            const cuenta = publishing.data.filter(element => element.Id_Competidor == null || element.Id_Competidor == 0)
            if(cuenta.length < 1){
              this.$hijonew({
                group: 'competidores1',
                type: 'warning',
                title: this.$t('dashboard.titlemodalad'),
                text: this.$t('competidore.modalcounterr'),
                duration: 1000,
                speed: 1000
              })
            }
            // console.log(competitors, cuenta);
            var nombreselem = []
            var nombreselem1 = []
            var orq = [];
            var orq1= [];
            var orq2= [];
            var orq3= [];
            var orq4= [];
            var labelsfecha = []
            var datetimes = [];
            var pushlabel = [];
            var datosline = [];
            var fg = [];

            //
            publishing.data.forEach((element,index) =>{
              const redsocial = nombreselem.filter(x => x.Nombre == element.Nombre);
              if(redsocial.length == 0){
                  nombreselem.push({
                    "RedSocial": element.Nombre,
                });
              }
            })
            
            // Obtener los el nombre ya sea de la cuenta o del competidor
            publishing.data.forEach((element,index) =>{
              const redsocial = nombreselem1.filter(x => x.Red_Social == element.Red_Social);
              if(redsocial.length == 0){
                  nombreselem1.push({
                    "RedSocial": element.Red_Social,
                });
              }
            })
            
            // obtener los post de cada cuenta (competidor o propia)
            nombreselem.forEach(x => {
              const redsocial = publishing.data.filter(element => element.Nombre == x.RedSocial);
              if(redsocial.length != 0){
                  orq.push({
                    "resocial": x.RedSocial,
                    "post": redsocial.length,
                    "competidor": redsocial[0].Id_Competidor != null && redsocial[0].Id_Competidor != 0 ? true : false
                });
              }
            })

            // orq1 : Obtiene los post de cada red social de mi cuenta
            // orq2 : Obtiene los post de cada red social del competidor
            // orq3 : Obtiene las reacciones de instagram y twitter 
            // orq4 : Obtiene las reacciones de Facebook
            reso1.forEach(x => {
              const redsocial = publishing.data.filter(element => element.Red_Social == x.RedSocial && element.Nombre == x.Nombre);
              if(redsocial.length != 0){
                if(orq1.length < 1){
                  orq1.push({
                        "resocial": x.RedSocial,
                        "post": redsocial.length,
                        "nombre": x.Nombre
                    });
                }
                else{
                    if(x.Nombre == redsocial[0].Nombre && x.Nombre == orq1[0].nombre){
                      orq1.push({
                        "resocial": x.RedSocial,
                        "post": redsocial.length,
                        "nombre": x.Nombre
                    });
                  }
                  else{
                    orq2.push({
                        "resocial": x.RedSocial,
                        "post": redsocial.length,
                        "nombre": x.Nombre
                    });
                    let countlikesAll = 0;
                    const post = publishing.data.filter(element => element.Id_Competidor != 0 && element.Id_Competidor != null)
                    if(x.RedSocial == "Instagram"){
                      const likesIn1 = post.filter(element => element.Intg_Media_Like_count != undefined ? countlikesAll = countlikesAll + parseInt(element.Intg_Media_Like_count) : countlikesAll )
                    }
                    else if(x.RedSocial == "Twitter"){
                      const likesTw1 = post.filter(element => element.twit_likes != undefined ? countlikesAll = countlikesAll + parseInt(element.twit_likes) : countlikesAll )
                    }
                    if(x.RedSocial != "Facebook"){
                      orq3.push({
                          "resocial": x.RedSocial,
                          "post": countlikesAll,
                          "nombre": x.Nombre
                      });
                    }
                    else{
                      let likes = 0;
                      let meencanta = 0;
                      let divierte = 0;
                      let sorprende = 0;
                      let enoja = 0;
                      // let entristece = 0;
                      post.filter(element => element.Fbk_ReaLikes != undefined ? likes = likes + parseInt(element.Fbk_ReaLikes) : likes )
                      post.filter(element => element.Fbk_ReaLOVE != undefined ? meencanta = meencanta + parseInt(element.Fbk_ReaLOVE) : meencanta )
                      post.filter(element => element.Fbk_ReaHAHA != undefined ? divierte = divierte + parseInt(element.Fbk_ReaHAHA) : divierte )
                      post.filter(element => element.Fbk_ReaWOW != undefined ? sorprende = sorprende + parseInt(element.Fbk_ReaWOW) : sorprende )
                      post.filter(element => element.Fbk_ReaANGRY != undefined ? enoja = enoja + parseInt(element.Fbk_ReaANGRY) : enoja )
                      // post.filter(element => element.Fbk_ReaSAD != undefined ? entristece = entristece + parseInt(element.Fbk_ReaSAD) : entristece )
                      orq4.push({
                          "resocial": "Likes",
                          "post": likes,
                          // "nombre": "Likes"
                      });
                      orq4.push({
                          "resocial": "Me encanta",
                          "post": meencanta,
                          // "nombre": "Me encanta"
                      });
                      orq4.push({
                          "resocial": "Me divierte",
                          "post": divierte,
                          // "nombre": "Me divierte"
                      });
                      orq4.push({
                          "resocial": "Me sorprende",
                          "post": sorprende,
                          // "nombre": "Me sorprende"
                      });
                      orq4.push({
                          "resocial": "Me enoja",
                          "post": enoja,
                          // "nombre": "Me enoja"
                      });
                      // orq4.push({
                      //     "resocial": "Me entristece",
                      //     "post": entristece,
                      //     // "nombre": "Me entristece"
                      // });
                    }
                  }
                }  
              }
            })
            console.log(orq4);
          // TODO: obtener las fechas para el line chart que tienen data
          publishing.data.forEach((x,i) => {
            let date = moment(x.Fecha, "MMMM DD YYYY").format("MM/DD");
            if(date == "Invalid date"){
             date = moment(new Date(x.Fecha), "MMMM DD YYYY").format("MM/DD");
            }
            publishing.data[i].fecha2 = date;
            let hoy = new Date(this.dateRange.endDate);
            let another = new Date(this.dateRange.startDate);
            var fecha1 = moment(this.dateRange.endDate);
            var fecha2 = moment(this.dateRange.startDate);
            if(i <= fecha1.diff(fecha2, 'days')){
              hoy = hoy.setDate(hoy.getDate() - i)
              hoy = new Date(hoy)
              hoy = moment(hoy, "YYYYMMDD").format("MM/DD");
              let fecha = datetimes.filter(otro => otro == date )
              let labels = labelsfecha.filter(otro => otro == hoy )
              if(fecha.length == 0){
                if( date != "Invalid date"){
                  datetimes.push(date);
                }
              }
              if(labels.length == 0){
                labelsfecha.push(hoy);
              }
            }
            else{
              let fecha = datetimes.filter(otro => otro == date )
              if(fecha.length == 0){
                if( date != "Invalid date"){
                  datetimes.push(date);
                }
              }
            }
          })

          //!Obtener los días de acuerdo al rango de fechas indicados
          let hoyrange = new Date(this.dateRange.endDate);
          let anotherrange = new Date(this.dateRange.startDate);
          var fecha1range = moment(this.dateRange.endDate);
          var fecha2range = moment(this.dateRange.startDate);
          if(labelsfecha.length != fecha1range.diff(fecha2range, 'days') + 1){
            for(let i= 0; i <= fecha1range.diff(fecha2range, 'days'); i++ ){
              let hoyrange1 = new Date(this.dateRange.endDate);
              let anotherrange1 = new Date(this.dateRange.startDate);
              var fecha1range1 = moment(this.dateRange.endDate);
              var fecha2range1 = moment(this.dateRange.startDate);
              hoyrange1 = hoyrange1.setDate(hoyrange1.getDate() - i)
              hoyrange1 = new Date(hoyrange1)
              hoyrange1 = moment(hoyrange1, "YYYYMMDD").format("MM/DD");
              let labels = labelsfecha.filter(otro => otro == hoyrange1 )
              if(labels.length == 0){
                  labelsfecha.push(hoyrange1);
              }
            }
          }

            labelsfecha.forEach(x => {
            orq.forEach(item => {
              const getResul = publishing.data.filter(otro => otro.fecha2 == x && otro.Nombre == item.resocial)
              if(getResul != 0){
                datosline.push({
                  RedSocial: item.resocial,
                  Fecha: x,
                  dato: getResul.length
                });
              }
            })
          })
          labelsfecha.forEach(x => {
            pushlabel.push({
                  label: x
              });
          })
          //! supuestamente ya vemos
            orq.forEach(xitem => {
              labelsfecha.forEach(item => {
                const dat = datosline.filter(otro => otro.RedSocial == xitem.resocial && otro.Fecha == item)
                if(dat.length != 0){
                  const dats = fg.filter(otro => otro.label == xitem.resocial)
                  if(dats.length != 0){
                    let indextmp = null;
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat[0].dato)
                  }
                  else{
                    fg.push({
                      label:xitem.resocial,
                      data: [],
                      fill: true,
                      borderColor: config.colors.primary,
                      borderWidth: 2,
                      borderDash: [],
                      backgroundColor:"transparent",
                      borderDashOffset: 0.0,
                      pointBackgroundColor: config.colors.primary,
                      pointBorderColor: "rgba(255,255,255,0)",
                      pointHoverBackgroundColor: config.colors.info,
                      pointBorderWidth: 20,
                      pointHoverRadius: 4,
                      pointHoverBorderWidth: 15,
                      pointRadius: 4,
                      tension: 0,
                      competidor: xitem.competidor
                    })
                    let indextmp = null;
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat[0].dato)
                  }
                }
                else{
                  const dats1 = fg.filter(otro => otro.label == xitem.resocial)
                  if(dats1.length != 0){
                    let indextmp = null;
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat.length)
                  }
                  else{
                    fg.push({
                      label:xitem.resocial,
                      data: [],
                      fill: true,
                      borderColor: config.colors.primary,
                      borderWidth: 2,
                      borderDash: [],
                      backgroundColor:"transparent",
                      borderDashOffset: 0.0,
                      pointBackgroundColor: config.colors.primary,
                      pointBorderColor: "rgba(255,255,255,0)",
                      pointHoverBackgroundColor: config.colors.info,
                      pointBorderWidth: 20,
                      pointHoverRadius: 4,
                      pointHoverBorderWidth: 15,
                      pointRadius: 4,
                      competidor: xitem.competidor
                    })
                    let indextmp = "";
                    let arr = fg.filter(function (element, index) { if (element.label == xitem.resocial) { indextmp = index } })
                    fg[indextmp].data.push(dat.length)
                  }
                }

              })
            })
            fg.forEach((element, index) => {
              if(pushlabel[index] != undefined){
                pushlabel[index].total = element;
              }
              else{
                pushlabel[index] = {};
                pushlabel[index].total = element;
              }
              
            })
            this.redesSociales = pushlabel;
            console.log(orq1, orq2);
            this.chartdata = orq1;
            this.chartdata2 = orq2;
            this.chartdata3 = orq3;
            this.chartdata4 = orq4;
            
            this.loaded = true;
            this.Carga();
          
          }
        }
        else{
          this.Carga()
          this.$hijonew({
              group: 'competidores1',
              type: 'danger',
              title: this.$t('dashboard.titlemodalad'),
              text: this.$t('competidore.modaldeserr'),
              duration: 1000,
              speed: 1000
          })
        }
      } catch (error) {
        this.Carga()
        console.log(error);
      }
      
    },
    async deleteCompet(id){
      console.log(id);
      const response = await this.axios.post('/bextsocial/removecompetitor',{
        id_competidor: id
      })
      console.log(response);
      this.fila = []
      this.getCompetitors()
    }
  },
  mounted() {
    //   this.$refs.rangeInput.$children[0].$el.children[2].textContent = "Rango de Fechas"
    console.log(this.$refs.closemodal);
  },
  watch: {
    dateRange: function () {
          this.dateNow();
          this.viewData(this.idCompetidor)
    },
    tags: function(){
        this.viewData(this.idCompetidor)
    },
    socialFacebook(){
      this.viewData(this.idCompetidor)
    },
    socialInstagram(){
      this.viewData(this.idCompetidor)
    },
    socialTwitter(){
      this.viewData(this.idCompetidor)
    }
  }
};
</script>