<template>
  <div class="competidores">
    <RangeDateSocial />
    <div class="mt-3">
      <competidores  />
    </div>
    <!-- <div class="col-12">
      <label
        >Files
        <input
          type="file"
          id="file"
          ref="file"
          v-on:change="handleFileUpload()"
        />
      </label>
      <button v-on:click="submitFile()">Submit</button>
    </div> -->
    <modalpeq group="competidores" position="bottom left"  />
  </div>
</template>
<script>
// import {mapState, mapMutations } from "vuex";
import Competidores from "@/components/competidores/competidores.component";
import RangeDateSocial from "@/components/rangedatesocial/RangeDateSocial.vue";
export default {
  name: "Competitors",
  components: {
    Competidores,
    RangeDateSocial
  },
  data() {
    return {
      // file: ''
    };
  },
  computed:{
      // ...mapState(["social", "socialFacebook", "socialInstagram", "socialTwitter"]),
  },
  created() {
      // this.getCompetitors();
      // this.Carga();
    // this.file()
  },
  methods: {
      // ...mapMutations(["Carga"]),
    
  },
};
</script>